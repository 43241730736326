import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "./footer.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"

const Footer = ({ disclaimer }) => {

    const data = useStaticQuery(graphql`
        {
            strapiFooter {
                Column1 {
                  DisplayOnMobile
                  Title
                  URL
                  Link_nofollow
                }
                Column2 {
                  DisplayOnMobile
                  URL
                  Title
                  Link_nofollow
                }
                Copyright
                Disclaimer
            }

            strapiSiteSettings{
                LogoLight{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
        }
    `)

    return (
        <>
            <section className="footer-section">
                <div className="footer-wrapper">

                    <div className="footer-grid" data-uk-grid>
                        <div className="uk-width-1-3@m uk-width-1-2@s footer-column uk-text-left">
                            <ul>
                                {data.strapiFooter.Column1.map((item, index) => {
                                    return (
                                        <li key={index}><a href={item.URL} rel={item.Link_nofollow ? "nofollow" : ""}>{item.Title}</a></li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="uk-width-1-3@m uk-width-1-2@s footer-column uk-text-left">
                            <ul>
                                {data.strapiFooter.Column2.map((item, index) => {
                                    return (
                                        <li key={index}><a href={item.URL} rel={item.Link_nofollow ? "nofollow" : ""}>{item.Title}</a></li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className="uk-width-1-3@m uk-width-1-2@s footer-column uk-text-left" >

                            <Link className="logoLight" to="/" aria-label="light-logo">
                                {data.strapiSiteSettings.LogoLight.localFile.childImageSharp ?
                                    <GatsbyImage image={data.strapiSiteSettings.LogoLight.localFile.childImageSharp.gatsbyImageData} alt="The insurers logo light" width="180" height="34.8"></GatsbyImage>
                                    : <img src={data.strapiSiteSettings.LogoLight.url} alt="The insurers logo light" width="180" height="34.8" />
                                }

                            </Link>
                            <p>{data.strapiFooter.Copyright ? data.strapiFooter.Copyright : 'Copyright © Vast Visibility Ltd ' + new Date().getFullYear() + '. All Rights Reserved'}</p>


                        </div>
                    </div>

                    <div className="footer__copyright">
                        {disclaimer ? <CustomMarkdown children={disclaimer}></CustomMarkdown> : ""}
                        <CustomMarkdown children={data.strapiFooter.Disclaimer}></CustomMarkdown>

                    </div>

                </div>
            </section>
        </>
    )
}

export default Footer