import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import GetImgUrl from "../../utils/customFunctions";
import "./provider-carousel.scss";

const ProviderCarousel = ({ images, title, subtitle, ctaUrl, ctaLabel }) => {
  
  return (
    <div className="provider-container padding-top padding-bottom lateral-padding">
      <div className="provider-content">
        <h2 className="provider-carousel-title">{title}</h2>
        <div className="provider-images">
          {images.map((item, index) => {
            
            return (
              <div className="provider-single-image-container">
                {" "}
                <GatsbyImage
                  image={item.gatsbyImageData}
                  alt={""}
                  className="provider-single-image"
                  key={index}
                />
              </div>
            );
          })}
        </div>

        {subtitle ? <p className="provider-carousel-subtitle">{subtitle}</p> : ''}
        {
        ctaUrl ?
        <div className="provider__cta">
          <a href={ctaUrl} className="cta">
            {ctaLabel}
          </a>
        </div>
        :''
        }
      </div>
    </div>
  );
};

export default ProviderCarousel;
