import React, { useEffect, useRef, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "./nav.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaPhone } from "react-icons/fa"
import CustomMarkdown from "../../utils/customMarkdown"
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

const Nav = ({ forcedDarkTheme, hideQuoteButton }) => {

    const data = useStaticQuery(graphql`
        {
            strapiMenu{
                MenuList{
                    Title
                    URL
                    DisplayOnMobile
                    Link_nofollow
                }
                GetQuoteTitle
                GetQuoteURL
                QuotePhoneNumber
                QuotePhoneTitle
            }

            strapiSiteSettings{
                LogoDark{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                LogoLight{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }

                StarRatingImage{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                ReviewerImage{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                ReviewShortText
                Rating
                TotalReviews
                
            }
        }
    
    `)


    const [ctaBarVisible, setCtaBarVisibility] = useState(false)
    const menu = useRef(null);
    const mobileMenu = useRef(null)

    function showHideCta() {
        var y = window.scrollY;
        if (y >= 400) {
            setCtaBarVisibility(true)
        } else {
            setCtaBarVisibility(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", showHideCta)
    }, [])


    const [openMenu, setOpenMenu] = useState(false)
    const [openMobileMenu, setOpenMobileMenu] = useState(false)

    const closeMenu = (e) => {
        if (menu.current && openMenu && !menu.current.contains(e.target)) {
            setTimeout(() => {
                setOpenMenu(false)
            }, 90)
        }
    }
    const closeMobileMenu = (e) => {
        if (mobileMenu.current && openMobileMenu && !mobileMenu.current.contains(e.target)) {
            setTimeout(() => {
                setOpenMobileMenu(false)
            }, 90)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', closeMenu)
        document.addEventListener('mousedown', closeMobileMenu)
        return () => {
            window.removeEventListener('mousedown', closeMenu);
            window.removeEventListener('mousedown', closeMobileMenu);
        };
    }, [openMenu, openMobileMenu])

    const [showElement, setShowElement] = useState(false)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true)
        }, 300);
    },
        [])

        // console.log(hideQuoteButton, "hide")

    return (
        <div>
            <div className={"nav-dark-theme navigationBar"}>
                <div data-uk-sticky=" sel-target: .uk-navbar-container; top:1;">
                    {/* nav bar with the logo */}
                    <nav className="nav-options uk-navbar-container  " data-uk-navbar>
                        <div className="uk-navbar-left nav-options-left" >

                            <div className={`menu-toggle uk-visible@m ${openMenu && 'menu-open'}`}
                                ref={menu}
                            >
                                <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn " data-uk-toggle="target: #offcanvas-menu"
                                    onClick={() => setOpenMenu(!openMenu)}
                                >
                                    <span className="ui-label">{openMenu ? <>Close <AiOutlineClose size={'1.1rem'} /></> : <>Menu <AiOutlineMenu size={'1.1rem'} /></>}</span>

                                </a>
                            </div>



                            <ul className="uk-navbar-nav">
                                <li>
                                    <a className="logoLight" href="/" rel="nofollow" aria-label="light-logo">
                                        {data.strapiSiteSettings.LogoLight.localFile.childImageSharp ?
                                            <GatsbyImage image={data.strapiSiteSettings.LogoLight.localFile.childImageSharp.gatsbyImageData} loading="eager" alt="The insurers logo light" width="180" height="34"></GatsbyImage>
                                            : <img src={data.strapiSiteSettings.LogoLight.url} alt="The insurers logo light" width="180" height="34" />
                                        }

                                    </a>
                                    <a className="logoDark" href="/" rel="nofollow">
                                        {data.strapiSiteSettings.LogoDark.localFile.childImageSharp ?
                                            <GatsbyImage image={data.strapiSiteSettings.LogoDark.localFile.childImageSharp.gatsbyImageData} loading="eager" alt="The insurers logo dark" width="180" height="34"></GatsbyImage>
                                            : <img src={data.strapiSiteSettings.LogoDark.url} alt="The insurers logo dark" width="180" height="34" />
                                        }

                                    </a>
                                </li>
                            </ul>
                        </div>

                        {/* middle navbar */}
                        <div className="uk-navbar-center uk-visible@m">
                            <ul className="uk-navbar-nav menu-items-nav">
                                {data.strapiMenu ? data.strapiMenu.MenuList.slice(0, 3).map((item, index) => {
                                    return (
                                        <li key={index} className={`menu-item-visible-index-` + index}>
                                            {/* <Link to={`/${item.URL.replace('/', '')}`}>{item.Title}</Link> */}
                                            <a href={item.URL} rel={item.Link_nofollow ? "nofollow" : ""}>{item.Title}</a>
                                        </li>
                                    )

                                }) : ""}
                            </ul>
                        </div>

                        {/* right navbar menu toggle button hidden on desktop but shown on mobile - align-right*/}
                        {showElement ?
                            <div className="uk-navbar-right uk-animation-fade" ref={mobileMenu}>

                                <div className="nav-right-container">
                                    {data.strapiMenu.QuotePhoneNumber ? <a href={'tel:' + data.strapiMenu.QuotePhoneNumber.replace(/[\s+.]/g, "")} className="nav-tel uk-visible@m">
                                        <div className="quote-phone-title">{data.strapiMenu.QuotePhoneTitle}</div>
                                        <div>{data.strapiMenu.QuotePhoneNumber}</div>
                                    </a> : ""}

                                    {data.strapiMenu.GetQuoteURL && (hideQuoteButton == false || hideQuoteButton == undefined) ? 
                                    <a className="nav-cta uk-visible@m" href={data.strapiMenu.GetQuoteURL}>
                                        {data.strapiMenu.GetQuoteTitle}
                                    </a> : ""}
                                </div>

                                {
                                    openMobileMenu ? <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn uk-hidden@m" data-uk-toggle="target: #offcanvas-menu" onClick={() => setOpenMobileMenu(false)}>
                                        <span className="uk-margin-small-right">Close <AiOutlineClose size={'1.1rem'} /></span></a>
                                        :
                                        <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn uk-hidden@m open-menu" data-uk-toggle="target: #offcanvas-menu" onClick={() => setOpenMobileMenu(true)}>
                                            <span className="uk-margin-small-right" >Menu<AiOutlineMenu size={'1.1rem'} /></span></a>
                                }


                            </div>
                            : ""
                        }


                        {/* mobile side menu only visible on mobile */}
                        <div id="offcanvas-menu" className="off-canv" data-uk-offcanvas="overlay: false; flip: false; mode: none" style={openMobileMenu ? { display: 'block' } : { display: 'none' }}>
                            <div className="uk-offcanvas-bar uk-flex uk-flex-column offcanvas-container canvas-container-mobile">

                                {/**
                                 * offcanvas call to action menu only visible on tablet & mobile
                                 */}
                                <div className="offcanvas-menu-cta-wrapper uk-hidden@m">
                                    <div className="offcanvas-menu-cta-container ">
                                        {data.strapiMenu.QuotePhoneTitle ? <a className="offcanvas-menu-cta-container-primary-cta" href={'tel:' + data.strapiMenu.QuotePhoneNumber.replace(/[\s+.]/g, "")}>
                                            <FaPhone size={"2rem"} />
                                            <span>{data.strapiMenu.QuotePhoneTitle}</span>
                                        </a> : ""}
                                        {data.strapiMenu.GetQuoteTitle && (hideQuoteButton == false || hideQuoteButton == undefined) ? <a className="offcanvas-menu-cta-container-secondary-cta" href={data.strapiMenu.GetQuoteURL}>
                                            {data.strapiMenu.GetQuoteTitle}
                                        </a> : ""}
                                    </div>
                                </div>


                                {/**
                                  * List of menu links
                                  * The first 3 links will be hidden on desktop because they are
                                  * visible in the main top menu but when resizing for mobile they
                                  * will become visible again in the offcanvas side menu.
                                  */}
                                <ul className="uk-nav uk-nav-primary uk-nav-left offcanvas-menu-list reposition">

                                    {data.strapiMenu ? data.strapiMenu.MenuList.map((item, index) => {
                                        return (
                                            item.DisplayOnMobile ?
                                                <li key={index} className={`offcanvas-menu-item-visible-index-` + index}>

                                                    <a href={item.URL} rel={item.Link_nofollow ? "nofollow" : ""}>{item.Title}</a>
                                                </li>
                                                : ""
                                        )
                                    }) : ""
                                    }

                                </ul>

                                {/**
                                 * REVIEWS.IO section
                                 */}
                                {(data.strapiSiteSettings.TotalReviews > 0) ? <div className="offcanvas-reviews-wrapper uk-hidden@m">
                                    <div className="offcanvas-reviews-images-container">
                                        <GatsbyImage image={data.strapiSiteSettings.StarRatingImage.localFile.childImageSharp.gatsbyImageData} alt="Rating stars"></GatsbyImage>
                                        <GatsbyImage image={data.strapiSiteSettings.ReviewerImage.localFile.childImageSharp.gatsbyImageData} alt="Rating stars"></GatsbyImage>
                                    </div>
                                    <div className="offcanvas-reviews-text-container">
                                        {<CustomMarkdown children={data.strapiSiteSettings.ReviewShortText} />}
                                    </div>
                                </div> : ""}





                            </div>

                        </div>

                    </nav>

                    {/**
                     * CTA bar that's visible only on mobile when user scrolls a bit down
                     */

                        ctaBarVisible ?
                            <div className="nav-cta-wrapper uk-hidden@m" data-uk-scrollspy="cls:uk-animation-fade">
                                <div className="nav-cta-container ">
                                    <a className="nav-cta-container-primary-cta" href={'tel:' + data.strapiMenu.QuotePhoneNumber.replace(/[\s+.]/g, "")}>
                                        <FaPhone size={"2rem"} />
                                        <span>{data.strapiMenu.QuotePhoneTitle}</span>
                                    </a>
                                    {
                                        (hideQuoteButton == false || hideQuoteButton == undefined) ?
                                            <a className="nav-cta-container-secondary-cta" href={data.strapiMenu.GetQuoteURL}>
                                                {data.strapiMenu.GetQuoteTitle}
                                            </a>
                                            : ""
                                    }

                                </div>
                            </div> : ""}



                </div>
            </div>
        </div>
    )
}

export default Nav