// import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server"
//import PropTypes from "prop-types"
import React from "react"
//import React from "react"

import "../styles/global.scss"
import UIkit from 'uikit'
import Seo from "../components/seo/seo.js"
import Nav from "../components/nav/nav.js"
import Footer from "../components/footer/footer.js"

//andrei: disable uikit js as it's not needed for now
//import UIkit from 'uikit'
//import Icons from 'uikit/dist/js/uikit-icons';



const Layout = ({ children, pageData, SEOTitle, SEODescription, footerDisclaimer, darkThemeNav, meta, canonical, hideQuoteButton}) => {

    //UIkit.use(Icons);
    const showElement = true;
    /*const [showElement, setShowElement] = useState(false)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true)
        }, 195);
    },
        [])*/

    return(
        <>
            {/* SEO section */}
            <Seo title={SEOTitle} description={SEODescription} meta={meta} canonical={canonical}></Seo>
            <Nav forcedDarkTheme={darkThemeNav} hideQuoteButton={hideQuoteButton}></Nav>

            <main>{children}</main>
            {
            showElement?
            /* footer section */
            <Footer disclaimer={footerDisclaimer}></Footer>
            :""
            }
        </>
    )
    
}
/*
Layout.propTypes = {
    children: PropTypes.node.isRequired,
}*/

export default Layout
